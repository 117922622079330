import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import { toaster, Spinner } from 'evergreen-ui';
import Context from '../../assets/js/Context';
import axios from 'axios';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
// import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// import Lottie from 'lottie-react-web'
// import animation from '../../assets/json/clean.json'
const cookies = new Cookies();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      spinBtn: false,
    }
  }
  signIN(e) {

    e.preventDefault()
    axios.post(host + `dashbord/login`, {
      email: this.state.email,
      password: this.state.password,
    })
      .then(response => {
        cookies.set("tokenUser", response.data.data.token, { expires: new Date(Date.now() + 604800000) });
        cookies.set("token", response.data.data.token, { expires: new Date(Date.now() + 604800000) });
        localStorage.setItem('role', response.data.data.role)
        console.log(response.data.data.role);
        if (response.data.data.role == 3) {
          window.location.href = "/packaging";
        } else if (response.data.data.role == 4) {
          window.location.href = "/Devices";
        }
        else if (response.data.data.role == 0) {
          window.location.href = "/print";
        }
        else if (response.data.data.role == -5) {
          window.location.href = "/s_home";
        }
        else if (response.data.data.role == -6) {
          window.location.href = "/s_home";
        }
        else if (response.data.data.role == -7) {
          window.location.href = "/s_home";
        }
        else {
          window.location.href = "/Cate2";

        }
        // window.location.href = "/Cate2";
        this.setState({ spinBtn: false })
      })
      .catch(error => {
        if (error.response) {
          this.setState({ spinBtn: false })
          toaster.danger("الرجاء التحقق من البريد الالكتروني وكلمة المرور");
        }
      });
  }
  render() {
    return (
      <Context.Consumer>{ctx => {
        // if (ctx.value.login==='false') {
        return (
          <div style={{ height: '100vh' }}>
            <div id='mahmed'>
              <Row style={{ marginRight: '0px', width: '80%' }} id='ahmed_row' >
                <Col md={6} id='ahm_col2' >
                  <img src={require('../../assets/img/logo.jpg')} id='logoLoginIMG' alt='logo' />
                </Col>
                <Col xs={12} md={6} id='ahm_col1' >
                  <div id='card_a21'  >
                    <div id='field1_ah' style={{ fontSize: '24px', fontWeight: '400', color: '#000', paddingTop: '3%' }}>تسجيل الدخول الى لوحه التحكم</div>

                    <div id='field1_ah'>
                      <input autoComplete='off' type='text' placeholder=' البريد الالكتروني' id='InputTExtDash1'
                        onChange={(e) => this.setState({ email: e.target.value })} />
                    </div>
                    <div id='field1_ah'>
                      <input autoComplete='off' type='password' placeholder=' كلمه المرور ' id='InputTExtDash1'
                        onChange={(e) => this.setState({ password: e.target.value })} />
                    </div>
                    <div id='log1_ah'>
                      {!this.state.spinBtn ? (
                        <div id='log_ah' onClick={(e) => {
                          this.setState({ spinBtn: true })
                          this.signIN(e)
                        }}>تسجيل الدخول</div>
                      ) : (
                        <div id='log_ah' ><Spinner size={24} /></div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
        // else if (ctx.value.login==='true') {
        //   window.location.href='/home'
        // }
        // else {
        //   return (
        //     <div style={{ width: '100%', height: 'calc(100vh - 80px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        //       <Lottie
        //         options={{
        //           animationData: animation
        //         }}
        //       />
        //     </div>
        //   )
        // }
      }
      </Context.Consumer>
    );
  }
}
export default Login;