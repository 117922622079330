import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import INV from '../../assets/img/in.jpg';
import SN from '../../assets/img/sn.webp';



import { Pane, Dialog, Spinner, SideSheet, Position } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Login from '../common/login';
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            image: [],
            Category: [],
            spin: false,
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/category`, { headers: header })
            .then(res => {
                console.log(res.data.data.categories);
                var arr = []
                for (let i = 0; i < res.data.data.categories.length; i++) {
                    if (res.data.data.categories[i].is_count) {
                        arr.push(res.data.data.categories[i])
                    }
                }
                this.setState({
                    Category: arr, spin: true
                });
            })
            .catch(error => { console.log(error.response) })

    }
    EditCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.nameCate);
        formData.append("is_count", 1);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/category/${id}`, method: "PUT", data: formData, headers: header })
            .then(response => {
                this.setState({ image: '' })
                this.componentDidMount()
                toast('تم التعديل بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/category/${id}?`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    AddCate() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.nameCate);
        formData.append("is_count", 1);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/category`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تم اضافة القسم بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                            return (
                                <div >
                                    <div id='navDashContiner'>
                            
                                    </div>

                                    <Row style={{ margin: 0 }}>
                                        <Col id='ColCateGory' xs={12} md={6} lg={3} >
                                            <Component initialState={{ spin: false, total: 1, menuOpen: false, editcount: false, edit: false, delete: false }}>
                                                {({ state, setState }) => (
                                                    <React.Fragment>
                                                        <div id='imageCateContiner'>
                                                            <Link to={`/Items2Dash/1`}>
                                                                <img id='imageCateGory' src={INV} alt='cate' />
                                                                <h6 style={{ marginTop: 10, textAlign: 'center' }}>مخزن المواد</h6>
                                                            </Link>

                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </Component>
                                        </Col>
                                        <Col id='ColCateGory' xs={12} md={6} lg={3} >
                                            <Component initialState={{ spin: false, total: 1, menuOpen: false, editcount: false, edit: false, delete: false }}>
                                                {({ state, setState }) => (
                                                    <React.Fragment>
                                                        <div id='imageCateContiner'>
                                                            <Link to={`/ItemsSana/2`}>
                                                                <img id='imageCateGory' src={SN} alt='cate' />
                                                                <h6 style={{ marginTop: 10, textAlign: 'center' }}>مخزن الصيانة</h6>
                                                            </Link>

                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </Component>
                                        </Col>
                                    </Row>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: '100vh ', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;