import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Pane, Dialog, Spinner, SideSheet, Position, SelectField } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Card, ListGroup, Button, Container, Form, Table, ButtonGroup, Alert } from 'react-bootstrap';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Logo from '../../assets/img/printlogo.jpg';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link } from 'react-router-dom';
import Login from '../common/login';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';

import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
const cookies = new Cookies();

const items = [
    {
        id: 0,
        name: 'Cobol'
    },
    {
        id: 1,
        name: 'JavaScript'
    },
    {
        id: 2,
        name: 'Basic'
    },
    {
        id: 3,
        name: 'PHP'
    },
    {
        id: 4,
        name: 'Java'
    }
]




let get_type=""

const formatResult = (item) => {
    return (
        <>
            <span style={{ display: 'block', textAlign: 'left', fontSize: 15, zIndex: 0 }}>{item.name}</span>
        </>
    )
}
class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            image: [],
            Category: [],
            spin: false,
            name: '',
            sn: '',
            notes: '',
            battery: "",
            id: "",
            itemsCount: 1,
            suppliers: [],
            items: [],
            t_price: 0,
            sup_id: "",
            type: "",


        }
        const urlParams = new URLSearchParams(window.location.search);

        let type = urlParams.get('type')

   
        if (type == "1") {
            get_type = "منتجات"
        } else {
            get_type = "صيانة"
        }
   
    }


    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);

        let type = urlParams.get('type')

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/Suppliers/all`, { headers: header })
            .then(res => {
                let arry = []
                this.setState({
                    suppliers: res.data.suppliers,

                });
            })
            .catch(error => { console.log(error.response) })



        axios.get(host + `dashbord/items/get/all?type=${type}`, { headers: header })
            .then(res => {
                let arry = []
                console.log(res.data.items);
                this.setState({
                    items: res.data.items,

                });
            })
            .catch(error => { console.log(error.response) })

    }
    get_list_price() {
        let price = 0
        for (let index = 0; index < this.state.itemsCount; index++) {
            let p = document.getElementById(`price${index + 1}`).value * document.getElementById(`count${index + 1}`).value

            document.getElementById(`priceAll${index + 1}`).value = p.toFixed(2)
            price = price + p
        }

        this.setState({ t_price: price.toFixed(2) })

    }

    send() {
        let sup_id = this.state.sup_id;
        let listDate = document.getElementById("listDate").value;
        let notes = document.getElementById("notes").value;
        let shipping = document.getElementById("shipping").value;
        let t_price = this.state.t_price
        let type = this.state.type




        if (!sup_id) {
            toast.error('حصل خطأ تأكد من صحة المعلومات', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return -1;
        }
        if (!type || type == "none") {
            toast.error('حصل خطأ تأكد من صحة المعلومات', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return -1;
        }
        if (!shipping) {
            toast.error('حصل خطأ تأكد من صحة المعلومات', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return -1;
        }
        if (!listDate) {
            toast.error('حصل خطأ تأكد من صحة المعلومات', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return -1;
        }
        let items = [];

        for (let index = 0; index < this.state.itemsCount; index++) {

            let count = document.getElementById(`count${index + 1}`).value;
            let items_id = document.getElementById(`item${index + 1}`).value;
            let price = document.getElementById(`price${index + 1}`).value;

            if (!count || count == "" || count < 1) {
                toast.error('حصل خطأ تأكد من صحة المعلومات', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return -1;
            }
            if (!items_id || items_id == "") {
                toast.error('حصل خطأ تأكد من صحة المعلومات', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return -1;
            }
            if (!price || price == "") {
                toast.error('حصل خطأ تأكد من صحة المعلومات', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return -1;
            }
            let obj = {
                count,
                price,
                items_id
            }
            items.push(obj)
        }

        let check = document.getElementById('custom-switch').checked;
        let check_transfer = document.getElementById('custom-tr').checked;
        let safeCheck = document.getElementById('safeCheck').checked;


        let new_price = 0;
        let p_price = document.getElementById('paidPrit').value;

        if (check) {


            new_price = t_price - p_price;
        } else {
            new_price = t_price;
        }

        document.getElementById('addSpinner').style.display = 'block';
        document.getElementById('addBtn').style.display = 'none';

        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/bill/new`, {
            "sup_id": sup_id,
            "listDate": listDate,
            'notes': notes,
            'shipping': shipping,
            "items": items,
            "check": check,
            'price': t_price,
            'p_price': p_price,
            'type': type,
            safeCheck: safeCheck,
            transfer: check_transfer
        })
            .then(response => {

                window.location.href = "/billsList";
                this.setState({ spinBtn: false })
            })
            .catch(error => {
                window.alert('حدث خطأ')


                document.getElementById('addSpinner').style.display = 'none';
                document.getElementById('addBtn').style.display = 'block';
            });


    }
    render() {

        if (cookies.get("token")) {
            return (
                <div>
                    <ToastContainer />
                    <div id='navDashContiner'>
                        <Link to={`billsList`}><  KeyboardBackspace style={{ color: "#fff", fontSize: 30 }} /></Link>

                    </div>
                    <Container>
                        <Row></Row>
                        <br></br>
                        <hr></hr>

                        <center>
                            <Alert variant={"info"}>
                                نوع الفاتورة {get_type}
                            </Alert>
                        </center>

                        <hr></hr>
                        <br></br>
                        <Row dir="rtl">
                            <Col >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>اسم التاجر </Form.Label>

                                    <ReactSearchAutocomplete
                                        items={this.state.suppliers}

                                        onSelect={(item) => {

                                            this.setState({ sup_id: item.id })
                                            this.get_list_price()
                                        }}
                                        maxLength={10}
                                        autoFocus
                                        formatResult={formatResult}
                                    />

                                </Form.Group>
                            </Col>
                            <Col >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label> تاريخ القائمة </Form.Label>
                                    <Form.Control type="date" id="listDate" />
                                </Form.Group>

                            </Col>
                            <Col>
                                <SelectField label="نوع النقل" onChange={(e) => {
                                    this.setState({ type: e.target.value });
                                    console.log(e.target.value);
                                }}>
                                    <option value="none" selected>
                                        اختر
                                    </option>
                                    <option value="in">داخل</option>
                                    <option value="out">خارجي</option>
                                </SelectField>

                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>  مصاريف اخرى</Form.Label>
                                    <Form.Control type="number" id="shipping" placeholder=" النقل او الحولات" />
                                </Form.Group>

                            </Col>
                            <Col>
                                <Form.Label htmlFor="inputPassword5">ملاحظات </Form.Label>
                                <Form.Control
                                    type="textarea"
                                    id="notes"

                                    aria-describedby="passwordHelpBlock"
                                />
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                            <Col md={{ span: 4, offset: 1 }}>

                                <ButtonGroup aria-label="Basic example">
                                    <Button variant="success" onClick={() => {
                                        this.setState({ itemsCount: this.state.itemsCount + 1 })
                                        this.get_list_price()
                                    }}>+</Button>
                                    <Button variant="light" disabled>{this.state.itemsCount}</Button>
                                    <Button variant="danger" onClick={() => {
                                        if (this.state.itemsCount > 1) {
                                            this.get_list_price()
                                            this.setState({ itemsCount: this.state.itemsCount - 1 })

                                        }

                                    }}>-</Button>
                                </ButtonGroup>
                            </Col>
                            <Col md={{ span: 4, offset: 1 }}>
                                <Form.Check

                                    type={"checkbox"}
                                    label={`ليس من القاصة`}
                                    id={`safeCheck`}
                                />

                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <Table striped bordered hover >
                                    <thead>
                                        <tr>
                                            <th> الكلي   </th>

                                            <th>سعر القطعة  </th>
                                            <th>العدد </th>
                                            <th colSpan={3} >المنتج</th>
                                            <th>#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(() => {
                                            let td = [];
                                            for (let i = 1; i <= this.state.itemsCount; i++) {
                                                td.push(<tr>
                                                    <td><input disabled style={{ textAlign: 'center', background: '#FFF', width: "100%", height: "50px" }} type='number' id={`priceAll${i}`} onChange={() => {
                                                        this.get_list_price()
                                                    }}></input></td>
                                                    <td><input style={{ textAlign: 'center', background: '#FFF', width: "100%", height: "50px" }} type='number' id={`price${i}`} onChange={() => {
                                                        this.get_list_price()
                                                    }}></input></td>
                                                    <td><input style={{ textAlign: 'center', background: '#FFF', width: "100%", height: "50px" }} type='number' id={`count${i}`} onChange={() => {
                                                        this.get_list_price()
                                                    }}></input></td>
                                                    <td colSpan={3} style={{ width: "40%", zIndex: 9 }}>
                                                        <input hidden width={0} id={`item${i}`} ></input>
                                                        <ReactSearchAutocomplete
                                                            items={this.state.items}

                                                            onSelect={(item) => {

                                                                document.getElementById(`item${i}`).value = item.id
                                                                document.getElementById(`price${i}`).value = item.dw_price

                                                            }}

                                                            maxLength={100}
                                                            autoFocus
                                                            formatResult={formatResult}
                                                        />

                                                    </td>
                                                    <td>{i}</td>
                                                </tr>);
                                            }
                                            return td;
                                        })()}

                                        <tr>

                                            <td colSpan={3}>{this.state.t_price}</td>
                                            <td colSpan={2}>المجموع الكلي </td>
                                        </tr>

                                    </tbody>
                                </Table>

                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Check // prettier-ignore
                                    dir='RTL'
                                    type="radio"
                                    inline
                                    name='gr'
                                    size={"lg"}
                                    id="custom-switch"
                                    label="دفع بالأجل"
                                    onChange={() => {

                                        let check = document.getElementById('custom-switch').checked;
                                        if (check) {
                                            document.getElementById('partdiv').style.display = "flex"
                                        } else {
                                            document.getElementById('partdiv').style.display = "none"

                                        }


                                    }}


                                />


                                <Form.Check // prettier-ignore
                                    dir='RTL'
                                    type="radio"
                                    inline
                                    name='gr'
                                    size={"lg"}
                                    id="custom-tr"
                                    label="تحويل"
                                    onChange={() => {
                                        let check = document.getElementById('custom-switch').checked;
                                        if (check) {
                                            document.getElementById('partdiv').style.display = "flex"
                                        } else {
                                            document.getElementById('partdiv').style.display = "none"

                                        }



                                    }}
                                />
                            </Col>



                        </Row>
                        <hr></hr>
                        <Row dir='rtl' style={{ display: "none" }} id="partdiv">
                            <Col>
                                <Form.Label htmlFor="inputPassword5">دفعة جزئية</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="paidPrit"
                                    onChange={(e) => {
                                        let p = this.state.t_price - e.target.value;
                                        document.getElementById('rem').value = p
                                    }}
                                    aria-describedby="passwordHelpBlock"
                                />
                            </Col>
                            <Col>
                                <Form.Label htmlFor="inputPassword5">المتبقي </Form.Label>
                                <Form.Control
                                    type="number"
                                    disabled
                                    id="rem"
                                    aria-describedby="passwordHelpBlock"
                                />
                            </Col>

                        </Row>
                        <hr></hr>
                        <Spinner id="addSpinner" style={{ display: 'none' }} animation="border" role="status">
                            <span className="visually-hidden">انتظار</span>
                        </Spinner>
                        <Button id={"addBtn"} onClick={() => {
                            this.send()
                        }}>اضافة</Button>
                    </Container>
                </div>
            )
        }
        else {
            return (
                <Login />
            )
        }

    }

}


export default Category;