import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import Component from "@reactions/component";
import { Spinner, Icon, TextInput, Pane, Label, Textarea, Dialog } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import { Row, Col, Container, Form, Button, Alert } from 'react-bootstrap';
import Login from './login';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { MultiSelect } from "react-multi-select-component";
import Multiselect from 'multiselect-react-dropdown';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import Select from 'react-select'
import zIndex from '@material-ui/core/styles/zIndex';

const options = [
    { label: "Grapes 🍇", value: "grapes", isDisabled: false },
    { label: "Mango 🥭", value: "mango", isDisabled: true },
    { label: "Strawberry 🍓", value: "strawberry", isDisabled: true },
];

const cookies = new Cookies();

const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
}

const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
}


const formatResult = (item) => {
    return (
        <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}> {item.name}</span>
        </>
    )
}

const handleOnFocus = () => {
    console.log('Focused')
}

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            outItems: [],
            spin: false,
            options: [],
            optionsSelcted: [],
            itemIn: {},
            itemOut: "",
            isLoding: false,
            fields: { value: 'name', disabled: 'disabled' }
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/category/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                // console.log(res.data.data.items);

                this.setState({
                    items: res.data.data.items, spin: true
                });
            })
            .catch(error => { console.log(error.response) })




        axios.get(host + `users/get/items/sana/all?cat_id=2`)
            .then(res => {
                this.setState({ items: res.data.items })

            })
            .catch(error => {
                console.log(error.response)
            });

        axios.get(host + `users/get/items/sana/all`)
            .then(res => {
                this.setState({ outItems: res.data.items })

            })
            .catch(error => {
                console.log(error.response)
            });




    }

    onSelect(selectedList) {
        console.log(selectedList.id);

        axios.get(host + `users/parts/${selectedList.id}`)
            .then(res => {
                console.log(res.data.parts);

                this.setState({ options: res.data.parts, itemIn: selectedList })

            })
            .catch(error => {
                console.log(error.response)
            });




    }

    onRemove(item) {
        this.setState({ options: [], itemIn: {} })

    }



    onSelectFin(selectedList) {
        console.log(selectedList.id);
        this.setState({ itemOut: selectedList })




    }

    onRemoveFin(item) {
        this.setState({ itemOut: {} })

    }

    addNew() {

        let _inItem = this.state.itemIn;
        let _ouItem = this.state.itemOut;
        let op = this.state.optionsSelcted;
        let c_id = document.getElementById("c_id").value
        let notes = document.getElementById("notes").value


        var radios = document.getElementsByName('group1');
        var radios2 = document.getElementsByName('group2');


        let check = ""
        let check2 = ""


        for (var i = 0, length = radios.length; i < length; i++) {
            if (radios[i].checked) {
                // do whatever you want with the checked radio

                check = radios[i].value
                // only one radio can be logically checked, don't check the rest
                break;
            }
        }
        for (var i = 0, length = radios2.length; i < length; i++) {
            if (radios2[i].checked) {
                // do whatever you want with the checked radio

                check2 = radios2[i].value
                // only one radio can be logically checked, don't check the rest
                break;
            }
        }

        if (!check2) {
            window.alert('اختر نوع الفحص  ')
            return -1
        }
        if (!c_id) {
            window.alert('ادخل الرقم ')
            return -1
        }
        if (!check) {
            window.alert('اختر نوع التحويل  ')
            return -1
        }


        if (!_ouItem) {
            window.alert('ادخل الجهاز النهائي مع الذاكرة ')
            return -1
        }

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("inItem", _inItem.id);
        formData.append("c_id", c_id);
        formData.append("ouItem", _ouItem.id);
        formData.append("check", check);
        formData.append("check2", check2);

        formData.append("notes", notes);


        if (op.length > 0) {
            for (let index = 0; index < op.length; index++) {
                formData.append("op_ids", op[index].id);

            }
        }

        axios({ url: host + `users/sana/orders/new`, method: "post", data: formData, headers: header })
            .then(response => {


                // window.alert(`رقم الجهاز هو ${response.data.id}`)
                window.location.href = `/phone_S?id=${response.data.id.id}`;
            })
            .catch(error => {

                window.alert(error.response.data.message)

            })



    }
    render() {
        if (cookies.get("tokenUser")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div id='HomeContiner'>
                                <div id='navDashContiner'>
                                    <Link to='/s_home'>
                                        <Icon icon="circle-arrow-left" size={30} color="#fff"
                                            style={{ cursor: 'pointer' }} />
                                    </Link>
                                </div>
                                <div id='HomeContiner1'>

                                    <Container>

                                        <Row style={{ direction: 'rtl', margin: 0, paddingTop: 50, zIndex: 10 }}>
                                            <Col dir='rtl'>
                                                <center> نوع الجهاز </center>
                                                <div id={'ContinerInPut'}>

                                                    <div style={{ width: "100%", zIndex: 50 }}>
                                                        <ReactSearchAutocomplete
                                                            items={this.state.items}

                                                            maxResults={3}

                                                            placeholder="اختر الجهاز"
                                                            onSearch={handleOnSearch}
                                                            onHover={handleOnHover}
                                                            onClear={(e) => {
                                                                this.onRemove(e)
                                                            }}
                                                            onSelect={(e) => {
                                                                this.onSelect(e)
                                                            }}
                                                            onFocus={handleOnFocus}
                                                            autoFocus
                                                            styling={{ borderRadius: 0 }}
                                                            formatResult={formatResult}
                                                        />
                                                    </div>
                                                </div>

                                            </Col>
                                            <Col dir='rtl'>

                                                <center>قطع الغيار المستخدمة</center>
                                                <div style={{ marginTop: "25px" }}>


                                                    <Select isMulti onChange={(newValue) => {

                                                        this.setState({ optionsSelcted: newValue })

                                                    }}


                                                        options={this.state.options} />


                                                </div>
                                            </Col>

                                        </Row>
                                        <hr></hr>
                                        <br></br>

                                        <Row style={{ direction: 'rtl', margin: 0, paddingTop: 30 }}>
                                            <Col dir='rtl'>
                                                <center> الجهاز النهائي </center>
                                                <div id={'ContinerInPut'}>
                                                    <div style={{ width: "100%", zIndex: 10 }}>
                                                        <ReactSearchAutocomplete

                                                            items={this.state.outItems}
                                                            maxResults={5}
                                                            placeholder="اختر الجهاز النهائي مع الذاكرة"
                                                            onSearch={handleOnSearch}
                                                            onHover={handleOnHover}
                                                            onClear={(e) => {
                                                                this.onRemoveFin(e)
                                                            }}
                                                            onSelect={(e) => {
                                                                this.onSelectFin(e)
                                                            }}
                                                            onFocus={handleOnFocus}
                                                            autoFocus
                                                            styling={{ borderRadius: 0 }}
                                                            formatResult={formatResult}
                                                        />
                                                    </div>
                                                </div>

                                            </Col>
                                            <Col dir='rtl'>
                                                <center>C ID</center>
                                                <div id={'ContinerInPut'}>
                                                    <TextInput placeholder='ادخل معرف الجهاز' style={{ width: "100%", height: '45px' }} id="c_id" />
                                                </div>




                                            </Col>
                                            <Col dir='rtl'>
                                                <Pane>
                                                    <center >الملاحظات</center >
                                                    <Textarea id="notes" placeholder="ادخل الملاحظات ان وجدت ..." />
                                                </Pane>

                                            </Col>

                                        </Row>
                                        <hr></hr>
                                        <br></br>

                                        <Row>
                                            <Col>
                                                <center>
                                                    <Alert variant={"info "}>
                                                        يرجى تحديد نوع الفحص او الصيانة
                                                    </Alert>
                                                </center>
                                                <Form.Check
                                                    reverse
                                                    inline
                                                    value={`new`}
                                                    label="بضاعه جديدة"
                                                    name="group2"
                                                    type={'radio'}

                                                />
                                                <Form.Check
                                                    reverse
                                                    inline
                                                    value={`rej`}
                                                    label="فحص راجع"
                                                    name="group2"
                                                    type={'radio'}

                                                />

                                                <Form.Check
                                                    reverse
                                                    inline
                                                    value={`san`}
                                                    label="صيانة"
                                                    name="group2"
                                                    type={'radio'}

                                                />


                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <br></br>

                                        <Row style={{ direction: 'rtl', margin: 0, paddingTop: 50 }}>


                                            <Col>
                                                <center>
                                                    <Alert variant={"success "}>
                                                        يرجى تحديد نوع   التحويل
                                                    </Alert>
                                                </center>
                                                <Form.Check
                                                    reverse
                                                    inline
                                                    value={`inv`}
                                                    label="تحويل للفحص"
                                                    name="group1"
                                                    type={"radio"}
                                                    id={`inv`}
                                                />
                                                <Form.Check
                                                    reverse
                                                    inline
                                                    value={`soft`}
                                                    label="تحويل سوفت"
                                                    name="group1"
                                                    type={"radio"}
                                                    id={`soft`}
                                                />

                                            </Col>
                                        </Row>
                                        <hr></hr>



                                        <center>



                                            <Component initialState={{ isShown: false, p_price: 0, p_count: 0, isConfirmLoading: false }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            title='اضافة جهاز'
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="اضافة"
                                                            cancelLabel="الغاء"
                                                            isConfirmLoading={state.isConfirmLoading}
                                                            onConfirm={() => {
                                                                // setState({ isConfirmLoading: true })
                                                                // this.get_inv(this.state.Getid)

                                                                let _inItem = this.state.itemIn;
                                                                let _ouItem = this.state.itemOut;
                                                                let op = this.state.optionsSelcted;
                                                                let c_id = document.getElementById("c_id").value
                                                                var radios = document.getElementsByName('group1');
                                                                var radios2 = document.getElementsByName('group2');


                                                                let check = ""
                                                                let check2 = ""


                                                                for (var i = 0, length = radios.length; i < length; i++) {
                                                                    if (radios[i].checked) {
                                                                        // do whatever you want with the checked radio

                                                                        check = radios[i].value
                                                                        // only one radio can be logically checked, don't check the rest
                                                                        break;
                                                                    }
                                                                }
                                                                for (var i = 0, length = radios2.length; i < length; i++) {
                                                                    if (radios2[i].checked) {
                                                                        // do whatever you want with the checked radio

                                                                        check2 = radios2[i].value
                                                                        // only one radio can be logically checked, don't check the rest
                                                                        break;
                                                                    }
                                                                }

                                                                if (!check2) {
                                                                    window.alert('اختر نوع الفحص  ')
                                                                    return -1
                                                                }
                                                                if (!c_id) {
                                                                    window.alert('ادخل الرقم ')
                                                                    return -1
                                                                }
                                                                if (!check) {
                                                                    window.alert('اختر نوع التحويل  ')
                                                                    return -1
                                                                }


                                                                if (!_ouItem) {
                                                                    window.alert('ادخل الجهاز النهائي مع الذاكرة ')
                                                                    return -1
                                                                }
                                                                setState({ isConfirmLoading: true })
                                                                this.addNew()

                                                            }}
                                                        >
                                                            <div>
                                                                <center>
                                                                    <Alert variant={"danger"}>
                                                                        هل تريد فعلن ادخل هذا الجهاز؟
                                                                    </Alert>
                                                                </center>
                                                            </div>

                                                        </Dialog>

                                                        <Button onClick={() => {
                                                            // this.addNew()
                                                            setState({ isShown: true })
                                                        }}> اضافة</Button>


                                                    </Pane>
                                                )}
                                            </Component>







                                        </center>

                                        <br></br>

                                    </Container>
                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;