import React from 'react';
import Context from '../../assets/js/Context';
import Component from "@reactions/component";
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { SideSheet, Position, Dialog } from 'evergreen-ui';
import qs from 'qs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from '../common/login';
import { Button, Row, Col, Container, Alert, Table } from 'react-bootstrap';
import Key from '@material-ui/icons/LockOpen';

const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            Category: [],
            spin: false,
            admins: [],
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/admins/all`, { headers: header })
            .then(res => {
                this.setState({
                    admins: res.data.admins, spin: true
                });
            })
            .catch(error => { console.log(error.response) })

    }

    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                    <Component initialState={{ isShown: false, nameUser: '', phoneUser: '', passwordUser: '', }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <SideSheet
                                                    isShown={state.isShown}
                                                    position={Position.LEFT}
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                >
                                                    <div margin={40}>
                                                        <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة موظف</div>
                                                        <div id='inputAdd2Continer' >
                                                            <div id={'ContinerInPut'} >
                                                                <label>اسم الموظف</label>
                                                                <input autoComplete='off' type='text' placeholder='اسم الموظف' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ nameUser: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>رقم الهاتف</label>
                                                                <input autoComplete='off' type='number' placeholder='رقم الهاتف' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ phoneUser: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>كلمه المرور</label>
                                                                <input autoComplete='off' type='text' placeholder='كلمه المرور' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ passwordUser: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div id='btnAddContiner'>
                                                            <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                axios.post(host + `users/auth/register`,
                                                                    qs.stringify({ name: state.nameUser, password: state.passwordUser, phone: state.phoneUser }), {
                                                                    headers: {
                                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        toast('تمت الاضافة بنجاح', {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });

                                                                    })
                                                                    .catch(error => {
                                                                        console.log(error.response.data.message)
                                                                        this.setState({ spinerBtn: false })
                                                                    })
                                                                setState({ isShown: false })
                                                            }}>اضافة</div>
                                                        </div>

                                                    </div>
                                                </SideSheet>
                                                <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة موظف</div>
                                            </React.Fragment>
                                        )}
                                    </Component>

                                </div>
                                <br></br>
                                <Container>
                                    <Row>

                                        <Col>
                                            <Alert key={'primary'} variant={'primary'}>
                                                <h4 style={{ textAlign: 'center' }}>تقارير الطلبات</h4>
                                            </Alert>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col>
                                            <Button href="/wattingselles" variant="primary" size="md">
                                                الانتظار</Button>
                                        </Col>

                                        <Col>
                                            <Button href="/check" variant="primary" size="md">
                                                الغير مؤكد</Button>
                                        </Col>

                                        <Col>
                                            <Button href="/miss" variant="primary" size="md">
                                                الايرد</Button>
                                        </Col>

                                        <Col>
                                            <Button href="/selles" variant="primary" size="md">
                                                المبيعات</Button>
                                        </Col>

                                    </Row>
                                    <hr></hr>
                                    <br></br>
                                    <br></br>
                                    <Row>

                                        <Col>
                                            <Alert key={'warning'} variant={'warning'}>
                                                <h4 style={{ textAlign: 'center' }}>ادارة الموظفين </h4>
                                            </Alert>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col>

                                            <Component initialState={{ isShown: false, nameUser: '', phoneUser: '', passwordUser: '', role: 'none' }}>
                                                {({ state, setState }) => (
                                                    <React.Fragment>
                                                        <SideSheet
                                                            isShown={state.isShown}
                                                            position={Position.LEFT}
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                        >
                                                            <div margin={40}>
                                                                <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة موظف صيانة</div>
                                                                <div id='inputAdd2Continer' >
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>اسم الموظف</label>
                                                                        <input autoComplete='off' type='text' placeholder='اسم الموظف' id='InputTExtDash1'
                                                                            onChange={(e) => setState({ nameUser: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>رقم الهاتف</label>
                                                                        <input autoComplete='off' type='number' placeholder='رقم الهاتف' id='InputTExtDash1'
                                                                            onChange={(e) => setState({ phoneUser: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>كلمه المرور</label>
                                                                        <input autoComplete='off' type='text' placeholder='كلمه المرور' id='InputTExtDash1'
                                                                            onChange={(e) => setState({ passwordUser: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>الوظيفة</label>
                                                                        <select onChange={(e) => {
                                                                            setState({ role: e.target.value })
                                                                        }} name="emp" id="InputTExtDash1">
                                                                            <option value="none">اختر الوظيفة</option>
                                                                            <option value="-5">صيانة</option>
                                                                            <option value="-6">سوفت</option>
                                                                            <option value="-7">فحص</option>
                                                                            <option value="3">تجهيز</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div id='btnAddContiner'>
                                                                    <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {

                                                                        if (state.role == "none") {
                                                                            window.alert('اختر الوظيفة')
                                                                            return -1;
                                                                        }


                                                                        axios.post(host + `dashbord/admin`,
                                                                            qs.stringify({ name: state.nameUser, password: state.passwordUser, email: state.phoneUser, role: state.role }), {
                                                                            headers: {
                                                                                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                toast('تمت الاضافة بنجاح', {
                                                                                    position: "bottom-center",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true
                                                                                });

                                                                            })
                                                                            .catch(error => {
                                                                                window.alert(error.response.data.message)
                                                                                console.log(error.response.data.message)
                                                                                this.setState({ spinerBtn: false })
                                                                            })


                                                                        setState({ isShown: false })
                                                                    }}>اضافة</div>
                                                                </div>
                                                                <hr></hr>
                                                                <br></br>
                                                                <div id='titleAddUser' style={{ backgroundColor: '#000' }}>جدول الموظفين</div>
                                                                <div>
                                                                    <br></br>
                                                                    <Table dir="rtl" striped bordered hover>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>اسم الموظف </th>
                                                                                <th>رقم الهاتف </th>
                                                                                <th> الوظيفة </th>
                                                                                <th>تعديل كلمة المرور</th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.admins.map((admin, i) =>
                                                                                <tr>
                                                                                    <td>{admin.id}</td>
                                                                                    <td>{admin.name}</td>
                                                                                    <td>{admin.email}</td>
                                                                                    <td>{admin.role}</td>
                                                                                    <td>
                                                                                        <Component initialState={{ isShown: false, nameUser: '', phoneUser: '', passwordUser: '', }}>
                                                                                            {({ state, setState }) => (
                                                                                                <React.Fragment>
                                                                                                    <Dialog
                                                                                                        isShown={state.isShown}
                                                                                                        confirmLabel='تعديل'
                                                                                                        cancelLabel='الغاء'
                                                                                                        onConfirm={() => {
                                                                                                            axios.post(host + `dashbord/admins/password`,
                                                                                                                qs.stringify({ password: state.passwordUser, id: admin.id, }), {
                                                                                                                headers: {
                                                                                                                    "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                                                                }
                                                                                                            })
                                                                                                                .then(response => {
                                                                                                                    toast('تمت التغير بنجاح', {
                                                                                                                        position: "bottom-center",
                                                                                                                        autoClose: 5000,
                                                                                                                        hideProgressBar: false,
                                                                                                                        closeOnClick: true,
                                                                                                                        pauseOnHover: true,
                                                                                                                        draggable: true
                                                                                                                    });

                                                                                                                })
                                                                                                                .catch(error => {
                                                                                                                    console.log(error.response.data.message)
                                                                                                                    this.setState({ spinerBtn: false })
                                                                                                                })
                                                                                                            // setState({ isShown: false })
                                                                                                        }}
                                                                                                        position={Position.LEFT}
                                                                                                        onCloseComplete={() => setState({ isShown: false })}
                                                                                                    >
                                                                                                        <div margin={40}>
                                                                                                            <div id='titleAddUser' style={{ backgroundColor: '#000' }}>تعديل كلمة المرور</div>
                                                                                                            <div id='inputAdd2Continer' >


                                                                                                                <div id={'ContinerInPut'} >
                                                                                                                    <label>كلمه المرور</label>
                                                                                                                    <input autoComplete='off' type='text' placeholder='كلمه المرور' id='InputTExtDash1'
                                                                                                                        onChange={(e) => setState({ passwordUser: e.target.value })} />
                                                                                                                </div>
                                                                                                            </div>


                                                                                                        </div>
                                                                                                    </Dialog>
                                                                                                    <Key style={{ cursor: "pointer", color: 'red', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })} />
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </Component>

                                                                                    </td>


                                                                                </tr>
                                                                            )}




                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                        </SideSheet>
                                                        <Button onClick={() => setState({ isShown: true })} variant="primary" size="md">
                                                            الصيانة والتجهيز</Button>
                                                        {/* <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة موظف</div> */}
                                                    </React.Fragment>
                                                )}
                                            </Component>



                                        </Col>

                                        {/* <Col>
                                            <Button href="/check" variant="primary" size="md">
                                                الغير مؤكد</Button>
                                        </Col>

                                        <Col>
                                            <Button href="/miss" variant="primary" size="md">
                                                الايرد</Button>
                                        </Col>

                                        <Col>
                                            <Button href="/selles" variant="primary" size="md">
                                                المبيعات</Button>
                                        </Col> */}

                                    </Row>
                                </Container>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;