/* eslint-disable no-loop-func */
import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import Component from "@reactions/component";
import { Pane, Dialog, Spinner, SideSheet, Position, Icon, CubeAddIcon, Button } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Add from '@material-ui/icons/Add';

import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@material-ui/icons/Edit';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import ImageIcon from '@material-ui/icons/Image';
import Campaign from '@material-ui/icons/AddAlert';

import RttIcon from '@material-ui/icons/FormatQuote';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Form from 'react-bootstrap/Form';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Login from '../common/login';
const columns = [
    { field: "prats", name: "قطع الغيار", options: { filter: false, sort: false, } },

    { field: "count", name: "العدد", options: { filter: false, sort: true, } },
    { field: "dwprice", name: "سعر الشراء", options: { filter: false, sort: true, } },

    { field: "name", name: "اسم المادة", options: { filter: false, sort: true, } },
    { field: "id", name: "#", options: { filter: false, sort: true, } },

];
const partsColm = [

    { field: "price", name: "سعر الشراء", options: { filter: false, sort: true, } },
    { field: "count", name: "عدد القطع ", options: { filter: false, sort: true, } },
    { field: "item", name: "الجهاز ", options: { filter: true, sort: true, } },
    { field: "name", name: "اسم المادة", options: { filter: false, sort: true, } },
    { field: "id", name: "#", options: { filter: false, sort: true, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: true,
    print: false,
    filter: true,
    rowsPerPage: 1001,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            Category: [],
            spin: false,
            CountItem: '',
            nameItem: '',
            priceItem: '',
            dw_price: '',
            barcode: 0,
            image: [],
            ads: [],
            ad_file: [],
            ad_file1: [],
            ad_file2: [],
            ad_file3: [],
            Parts: []
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },
            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    add_Ad(id) {



        const name = document.getElementById('adName').value
        const body = document.getElementById('adBody').value
        const price = document.getElementById('adPrice').value
        const page = document.getElementById('adselectPage').value
        const count = document.getElementById('adCount').value
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", name);
        formData.append("item_id", id);
        formData.append("price", price);
        formData.append("count", count);
        formData.append("body", body);
        formData.append("page_id", page);
        formData.append("file", this.state.ad_file);
        formData.append("file", this.state.ad_file1);
        formData.append("file", this.state.ad_file2);
        formData.append("file", this.state.ad_file3);

        axios({ url: host + `dashbord/shop/items/add`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.setState({ spinerBtn: false })
                this.componentDidMount()

                toast('تم اضافه الاعلان', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data) })

    }
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let pages = []
        axios.get(host + `users/part/all/`, { headers: header })
            .then(res => {
                let parts = res.data.Parts
                let arry = []
                for (let index = 0; index < parts.length; index++) {
                    let obj = {
                        id: parts[index].id,
                        name: parts[index].name,
                        item: parts[index].item.name,
                        count: parts[index].count,
                        price: parts[index].price,


                    }
                    arry.push(obj)

                }
                this.setState({ Parts: arry })
            })
            .catch(error => {
                console.log(error.response)
            });
        axios.get(host + `dashbord/component/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                let arr = [];
                for (let i = 0; i < res.data.data.itmes.length; i++) {
                    let obj = {
                        id: res.data.data.itmes[i].id,
                        name: res.data.data.itmes[i].name,
                        dwprice: res.data.data.itmes[i].dw_price,
                        price: res.data.data.itmes[i].price,
                        count: res.data.data.itmes[i].count,
                        is_phone: 'منتج',
                        barcode: res.data.data.itmes[i].barcode,
                        prats:
                            <Component initialState={{ isShown: false }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <SideSheet
                                            isShown={state.isShown}
                                            title='اضافة اعلان'
                                            onCloseComplete={() => setState({ isShown: false })}
                                            confirmLabel="اضافة"
                                            cancelLabel="الغاء"
                                            onConfirm={() => {
                                                // console.log(state.img);
                                                this.add_Ad(res.data.data.itmes[i].id)
                                                setState({ isShown: false })
                                            }}
                                        >
                                            <div style={{ direction: 'rtl' }}>
                                                <br></br>
                                                <center>
                                                    <Alert variant={"info"}>
                                                        قطع الغيار المتوفرت لجهاز {res.data.data.itmes[i].name}
                                                    </Alert>
                                                </center>

                                                <br></br>

                                                <Component initialState={{ isShown: false, p_price: 0, p_count: 0 }}>
                                                    {({ state, setState }) => (
                                                        <Pane>
                                                            <Dialog
                                                                isShown={state.isShown}
                                                                title='اضافة عدد'
                                                                onCloseComplete={() => setState({ isShown: false })}
                                                                confirmLabel="اضافة"
                                                                cancelLabel="الغاء"
                                                                onConfirm={() => {
                                                                    let count = document.getElementById("p_count").value
                                                                    let price = document.getElementById("p_price").value
                                                                    let name = document.getElementById("p_name").value

                                                                    axios.post(host + `users/part/new`,
                                                                        qs.stringify({
                                                                            count: count,
                                                                            price: price,
                                                                            name: name,
                                                                            item_id: res.data.data.itmes[i].id,
                                                                        }), {
                                                                        headers: {
                                                                            "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                        }
                                                                    })
                                                                        .then(response => {

                                                                            window.alert('تم اضافة القطع')
                                                                            this.componentDidMount()
                                                                            // window.location.reload()
                                                                        })
                                                                        .catch(error => {

                                                                        })


                                                                    // console.log(state.img);
                                                                    // this.EditCount(res.data.data.itmes[i].id)
                                                                    // setState({ isShown: false })
                                                                }}
                                                            >
                                                                <div style={{ direction: 'rtl' }}>
                                                                    <center>

                                                                        <Alert variant={"info"}>
                                                                            اضافة قطع غيار الى لجهاز {<b style={{ color: "red" }}>{res.data.data.itmes[i].name}</b>}
                                                                        </Alert>
                                                                    </center>

                                                                    <div id={'ContinerInPut'} >
                                                                        <label>السعر</label>
                                                                        <input style={{ border: "var(--gray) 1px solid", padding: 10 }} autoComplete='off' type='number' placeholder='العدد  ' id='p_price'
                                                                        />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>العدد</label>
                                                                        <input style={{ border: "var(--gray) 1px solid", padding: 10 }} autoComplete='off' type='number' placeholder='السعر  ' id='p_count'
                                                                        />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>اسم المادة</label>
                                                                        <input style={{ border: "var(--gray) 1px solid", padding: 10 }} autoComplete='off' type='text' placeholder='اسم المادة  ' id='p_name'
                                                                        />
                                                                    </div>

                                                                </div>
                                                            </Dialog>


                                                            <Button onClick={() => { setState({ isShown: true }) }} appearance="primary" intent="success" marginY={8} marginRight={12} iconAfter={CubeAddIcon}>
                                                                اضافة قطع غيار
                                                            </Button>
                                                        </Pane>
                                                    )}
                                                </Component>,

                                                <br></br>

                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>اسم المادة</th>
                                                            <th>التكلفة</th>
                                                            <th>العدد </th>
                                                            <th>اضافة </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {res.data.data.itmes[i].part.map(p =>

                                                            <tr>
                                                                <td>{p.id}</td>
                                                                <td>{p.name}</td>
                                                                <td>{p.price}</td>
                                                                <td>{p.count}</td>
                                                                <td>

                                                                    <Component initialState={{ isShown: false, p_price: 0, p_count: 0 }}>
                                                                        {({ state, setState }) => (
                                                                            <Pane>
                                                                                <Dialog
                                                                                    isShown={state.isShown}
                                                                                    title='اضافة عدد'
                                                                                    onCloseComplete={() => setState({ isShown: false })}
                                                                                    confirmLabel="اضافة"
                                                                                    cancelLabel="الغاء"
                                                                                    onConfirm={() => {
                                                                                        let count = document.getElementById("p_count").value
                                                                                        let price = document.getElementById("p_price").value

                                                                                        axios.post(host + `users/part/add`,
                                                                                            qs.stringify({
                                                                                                count: count,
                                                                                                price: price,
                                                                                                p_id: p.id,
                                                                                            }), {
                                                                                            headers: {
                                                                                                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                                            }
                                                                                        })
                                                                                            .then(response => {

                                                                                                window.alert('تم اضافة القطع')
                                                                                                this.componentDidMount()
                                                                                                // window.location.reload()
                                                                                            })
                                                                                            .catch(error => {

                                                                                            })


                                                                                        // console.log(state.img);
                                                                                        // this.EditCount(res.data.data.itmes[i].id)
                                                                                        // setState({ isShown: false })
                                                                                    }}
                                                                                >
                                                                                    <div style={{ direction: 'rtl' }}>
                                                                                        <center>

                                                                                            <Alert variant={"info"}>
                                                                                                اضافة قطع غيار الى {<b style={{ color: "red" }}>{p.name}</b>} لجهاز {<b style={{ color: "red" }}>{res.data.data.itmes[i].name}</b>}
                                                                                            </Alert>
                                                                                        </center>

                                                                                        <div id={'ContinerInPut'} >
                                                                                            <label>السعر</label>
                                                                                            <input style={{ border: "var(--gray) 1px solid", padding: 10 }} autoComplete='off' type='number' placeholder='العدد  ' id='p_price'
                                                                                            />
                                                                                        </div>
                                                                                        <div id={'ContinerInPut'} >
                                                                                            <label>العدد</label>
                                                                                            <input style={{ border: "var(--gray) 1px solid", padding: 10 }} autoComplete='off' type='number' placeholder='السعر  ' id='p_count'
                                                                                            />
                                                                                        </div>

                                                                                    </div>
                                                                                </Dialog>
                                                                                <Add style={{ cursor: 'pointer', color: '#28a745', fontSize: 30 }}
                                                                                    onClick={() => { setState({ isShown: true }) }} />
                                                                            </Pane>
                                                                        )}
                                                                    </Component>


                                                                </td>
                                                            </tr>
                                                        )}


                                                    </tbody>
                                                </Table>
                                            </div>
                                        </SideSheet >
                                        <CubeAddIcon size={25} style={{ cursor: 'pointer', color: '#d7a305' }}
                                            onClick={() => { setState({ isShown: true }) }} />
                                    </Pane>
                                )
                                }
                            </Component>,

                        photo: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasHeader={false}
                                        hasFooter={false}
                                    >
                                        <div>
                                            <img src={host + 'files/' + res.data.data.itmes[i].photo} alt='img' id='imageItemDash' />

                                        </div>
                                    </Dialog>
                                    <ImageIcon style={{ cursor: 'pointer', color: '#08aba3' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        edit: <EditIcon
                            onClick={() => {
                                window.open(`/Edit/${res.data.data.itmes[i].id}`)
                                localStorage.setItem('nameIT', res.data.data.itmes[i].name)
                                localStorage.setItem('SpriceIT', res.data.data.itmes[i].dw_price)
                                localStorage.setItem('PpriceIT', res.data.data.itmes[i].price)
                                localStorage.setItem('BarcodeIT', res.data.data.itmes[i].barcode)
                                localStorage.setItem('category_id', this.props.match.params.id)
                            }}
                            style={{ cursor: 'pointer', color: '#4752d1' }} />,
                        delete: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="حذف قسم "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="حذف"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.DeleteCate(res.data.data.itmes[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> هل انت متأكد من عملية الحذف</span>
                                    </Dialog>
                                    <DeleteForeverIcon style={{ cursor: 'pointer', color: '#ec7373' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                    };
                    if (res.data.data.itmes[i].is_phone == 1) {
                        obj.is_phone = "موبايل"
                    }
                    arr.push(obj);
                }

                let sort_arry = arr.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

                this.setState({
                    Category: sort_arry, spin: true
                });
            })
            .catch(error => { console.log(error) })


    }
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/component/${id}?`, { headers: header })
            .then(response => {

                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    EditCount(id) {
        // const price = document.getElementById('priceAll').value
        const cout = document.getElementById('coutsAll').value
        // const name = document.getElementById('nameAll').value


        //    const s_price = Number(price) / Number(cout)


        axios.put(host + `dashbord/components/count/${id}`,
            qs.stringify({ count: cout, price: 0, name: "0" }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        })
            .then(response => {
                this.componentDidMount();
                toast('تم اضافة العدد بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => {
                console.log(error.response.data.message)
                this.setState({ spinerBtn: false })
            })
    }
    AddItem(nameItem, priceItem, dw_price, barcode) {





        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", nameItem);
        formData.append("count", 0);
        formData.append("price", 55);
        formData.append("dw_price", dw_price);
        formData.append("barcode", "barcode");
        formData.append("is_phone", 1);
        formData.append("hasCount", true);
        formData.append("category_id", this.props.match.params.id);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/component/add/`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.setState({ spinerBtn: false })
                this.componentDidMount()

                toast('تم اضافة المادة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data) })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                            return (
                                <div >
                                    <div id='navDashContiner'>
                                        <Link to='/Cate2'>
                                            <Icon icon="circle-arrow-left" size={30} color="#fff"
                                                style={{ cursor: 'pointer' }} />
                                        </Link>
                                        <Component initialState={{ isShown: false, nameItem: "", dw_price: 0, priceItem: 0, barcode: "", priceItem: 0, }}>
                                            {({ state, setState }) => (
                                                <React.Fragment>
                                                    <SideSheet
                                                        isShown={state.isShown}
                                                        position={Position.LEFT}
                                                        onCloseComplete={() => setState({ isShown: false })}
                                                    >
                                                        <div margin={40} style={{ direction: 'rtl' }}>
                                                            <div id='titleAddAppBar'>اضافة مادة</div>
                                                            <div id='inputAddContiner'>
                                                                <div id={'ContinerInPut'} >
                                                                    <label>اسم المادة </label>
                                                                    <input autoComplete='off' type='text' placeholder='اسم المادة ' id='InputTExtDash1'
                                                                        onChange={(e) => setState({ nameItem: e.target.value })} />
                                                                </div>
                                                                <div id={'ContinerInPut'} >
                                                                    <label>سعر الشراء </label>
                                                                    <input autoComplete='off' type='number' placeholder='سعر الشراء ' id='InputTExtDash1'
                                                                        onChange={(e) => setState({ dw_price: e.target.value })} />
                                                                </div>



                                                            </div>
                                                            <div id='btnAddItemContiner'>
                                                                {!this.state.spinerBtn ? (
                                                                    <div id='btnAddItem' onClick={() => {
                                                                        this.setState({ spinerBtn: true })
                                                                        this.AddItem(state.nameItem, state.priceItem, state.dw_price, state.barcode)
                                                                        // setState({ isShown: false })
                                                                    }}>اضافه</div>

                                                                ) : (
                                                                    <div id='btnAddItem'><Spinner size={24} /></div>

                                                                )}


                                                            </div>

                                                        </div>
                                                    </SideSheet>
                                                    <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة مادة</div>
                                                </React.Fragment>
                                            )}
                                        </Component>

                                    </div>

                                    <Tabs
                                        defaultActiveKey="home"
                                        id="uncontrolled-tab-example"
                                        className="mb-3"
                                    >
                                        <Tab eventKey="home" title="اجهزة الصيانة">
                                            <div className='DataTableContiner'>
                                                <MuiThemeProvider
                                                    theme={this.getMuiTheme()}>
                                                    <MaterialDatatable data={this.state.Category} columns={columns} options={options} />
                                                </MuiThemeProvider>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="profile" title="قطع الغيار">
                                            <MuiThemeProvider
                                                theme={this.getMuiTheme()}>
                                                <MaterialDatatable data={this.state.Parts} columns={partsColm} options={options} />
                                            </MuiThemeProvider>
                                        </Tab>

                                    </Tabs>

                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: 'calc(100vh)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;